<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="充电口名称"
            ><el-input
              placeholder="请输入关键词"
              v-model.trim="conditions.name"
              clearable
            >
            </el-input
          ></el-form-item>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-button type="primary" class="buttons" @click="handleAdd"
              >新增充电口</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table">
        <el-descriptions
          style="margin-left: 10px"
          :title="'当前充电站：' + stationName + '当前充电桩：' + pileName"
        ></el-descriptions>
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="序号"
            type="index"
            width="50px"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="充电口名称"
            prop="portName"
          ></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="创建时间"
            prop="gmtCreate"
          ></el-table-column>
          <el-table-column show-overflow-tooltip label="状态">
            <template v-slot="scope">
              <el-tag
                v-show="scope.row.status"
                size="small"
                :type="enumStatus[scope.row.status]?.type"
                >{{ enumStatus[scope.row.status]?.label }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                type="text"
                size="mini"
                @click.stop="handleEdit(scope.row)"
              >
                修改
              </el-button>
              <el-button
                type="text"
                size="mini"
                @click="handleDelete(scope.row.id)"
                style="color: rgb(254, 90, 36)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
        <AddPort
          v-if="showAdd"
          ref="addPortRef"
          @confirm="handleConfirm"
          @cancel="handleCancel"
        ></AddPort>
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from "@/components/pagination.vue";
import AddPort from "./addPort.vue";
export default {
  props: {
    portId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      enumStatus: {
        1: { label: "空闲", type: "" },
        2: { label: "充电中", type: "success" },
        3: { label: "故障", type: "danger" },
      },
      stationId: this.$route.query.stationId,
      stationName: this.$route.query.stationName,
      pileId: this.$route.query.pileId,
      pileName: this.$route.query.pileName,
      conditions: {
        name: "",
        page: 1,
        limit: 10,
      },
      total: 0,
      tableData: [],
      showAdd: false,
    };
  },
  components: {
    AddPort,
    Pagination
  },
  created() {
    this.getList();
  },
  methods: {
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/ChargePort/list",
        "get",
        {
          pileId: this.pileId,
          ...this.conditions,
        }
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableData = data.data.list;
      this.total = data.data.totalCount;
    },
    handleSearch() {
      antiShake((_) => {
        this.conditions.page = 1;
        this.getList();
      });
    },
    handleAdd() {
      this.showAdd = true;
      this.$nextTick(() => {
        this.$refs.addPortRef.isEdit = false;
        this.$refs.addPortRef.formRef.resetFields();
      });
    },

    handleConfirm() {
      this.showAdd = false;
      this.getList();
    },
    handleCancel() {
      this.showAdd = false;
    },
    handleEdit(row) {
      this.showAdd = true;
      this.$nextTick(() => {
        this.$refs.addPortRef.id = row.id;
        this.$refs.addPortRef.isEdit = true;
        for (let key in this.$refs.addPortRef.form) {
          this.$refs.addPortRef.form[key] = row[key];
        }
      });
    },
    handleDelete(id) {
      this.$messageBox
        .confirm("确认删除该充电站, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/asset/ChargePort/delete",
            "post",
            {
              ids: [id],
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.getList();
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
